<template>
  <div class="term-privacy-page w-100">
    <SectionFAQ />
    <SectionPhone class="mt-5 pb-3 pt-3 pb-md-5 pb-xxl-5 bg-white" />
  </div>
</template>

<script>
import SectionFAQ from './SectionsAbout/SectionFAQ'
import SectionPhone from "@/views/Landing/SectionPhone";

export default {
  name: "FAQ",
  title: "faq",
  components: {
    SectionPhone,
    SectionFAQ
  },
};
</script>