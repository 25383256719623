<template>
  <div class="section-content section-pickup pt-4 pb-5 w-100 container">
    <b-container class="container-xlg m-auto pl-xxl-0 pr-xxl-0 about-content">
      <div class="top-event d-flex flex-column flex-md-column">
        <esports-title
          title="FAQ"
          class="pb-2 pt-2 mb-2 mt-2 mt-xxl-4 pb-xxl-5 article-page"
          sub_title="よくある質問"
        />
      </div>

      <SectionAboutTitle class="mt-0" title="よくある質問">
            <p>
                  <span class="sub-title">投票やドネト、交換について</span><br />
            </p>
            <!-- <p class="pl-3">
                  ドネトとはなんですか？<br />
                  大会優勝者を予想し、的中した人の中から抽選でドネトをプレゼントしています。ドネトは1ドネト＝1Amazonギフトとして、3000ドネトからAmazonギフトに交換可能となっています。
            </p> -->
            <p>
                  <span class="sub-title">Q.</span>ドネトとはなんですか？<br />
                  <span class="sub-title">A.</span>大会優勝者を予想し、的中した人の中から抽選でドネトをプレゼントしています。ドネトは1ドネト＝1Amazonギフトとして、3000ドネトからAmazonギフトに交換可能となっています。<br />
            </p>
            <p>
                  <span class="sub-title">Q.</span>ドネトが付与されるまでの期間はどれくらいですか？<br />
                  <span class="sub-title">A.</span>大会の優勝者が決定した日から7営業日後までにメールにて当選通知と同時にドネトの付与を行います。<br />
            </p>
            <p>
                  <span class="sub-title">Q.</span>ドネトを交換した履歴はどこで確認できますか？<br />
                  <span class="sub-title">A.</span>マイページの「ドネト履歴」から確認できます。<br />
            </p>
            <p>
                  <span class="sub-title">Q.</span>交換可能なドネト数はどこで確認できますか？<br />
                  <span class="sub-title">A.</span>マイページTOPから確認できます。<br />
            </p>
            <p>
                  <span class="sub-title">Q.</span>投票券に有効期限はありますか？<br />
                  <span class="sub-title">A.</span>ありません。<br />
            </p>
            <p>
                  <span class="sub-title">Q.</span>ドネトに有効期限はありますか？<br />
                  <span class="sub-title">A.</span>最後にログインしてから6ヶ月間経過で失効します。<br />
            </p>
            <p>
                  <span class="sub-title">Q.</span>ドネト交換に上限はありますか？<br />
                  <span class="sub-title">A.</span>10000ドネトが一度に交換できる上限となります。<br />
            </p>
            <p>
                  <span class="sub-title">Q.</span>ポイント交換に下限はありますか？<br />
                  <span class="sub-title">A.</span>3000ドネトから交換可能です。<br />
            </p>
            <p>
                  <span class="sub-title">Q.</span>ドネト交換してからAmazonギフトを受け取るまでにどれくらいの期間がかかりますか？<br />
                  <span class="sub-title">A.</span>1ヶ月程度です。<br />
            </p>
            <!-- <p class="pl-3">
                  マイページの各履歴の情報が正しくありません。<br />
                  お問い合わせからご連絡お願いします。
            </p> -->
            <p>
                  <span class="sub-title">Q.</span>マイページの各履歴の情報が正しくありません。<br />
                  <span class="sub-title">A.</span>お問い合わせからご連絡お願いします。<br />
            </p>
            <p>
                  <span class="sub-title">Q.</span>ドネトの交換にはどのくらい時間がかかりますか？<br />
                  <span class="sub-title">A.</span>1ヶ月程度です。<br />
            </p>
            <p class="mt-4 pt-3">
                  <span class="sub-title">会員登録情報について</span>
            </p>
            <p>
                  <span class="sub-title">Q.</span>パスワードを忘れました。<br />
                  <span class="sub-title">A.</span>パスワードを忘れてしまった場合はパスワードをリセットして再設定する必要があります。<br />
                  &nbsp;&nbsp;&nbsp;&nbsp;下記URLよりご登録のメールアドレスを入力して「確認する」ボタンをクリックしてください。<br />
                  &nbsp;&nbsp;&nbsp;&nbsp;<b-link to="/forgot-password">{{ URL_FRONT_END }}/forgot-password</b-link><br />
                  &nbsp;&nbsp;&nbsp;&nbsp;入力したメールアドレス宛に認証番号が送信されます。<br />
                  &nbsp;&nbsp;&nbsp;&nbsp;認証番号を入力の上、新しいパスワードを設定してください。
            </p>
            <p>
                  <span class="sub-title">Q.</span>メールアドレスの変更は可能ですか？<br />
                  <span class="sub-title">A.</span>申し訳ありませんが、変更は不可能です。<br />
            </p>
      </SectionAboutTitle>
    </b-container>
  </div>
</template>

<script>
import SectionAboutTitle from './SectionAboutTitle';

export default {
    name: "SectionFAQ",
    components: { SectionAboutTitle },
    data() {
          return {
                URL_FRONT_END:  process.env.VUE_APP_FRONT_END
           }
    }

}
</script>